export enum LocalStorageKeys {
    BackgroundColor = 'backgroundColor',
    DebugBot = 'debugBot',
    Theme = 'theme',
    UnitTextSize = 'unitTextSize',
    Volume = 'volume',
};

export function getLocalStorageKeyVerifyNotif(userId: number): string {
    return `${userId}_VERIFY_NOTIF`;
}

export function getLocalStorageKeyVerifyNotifTime(userId: number): string {
    return `${userId}_VERIFY_NOTIF_TIME`;
}
