import { UserGetProfileResponse } from "../types/user";
import fetchPost from "../util/fetchpost"

export async function userGetProfile(): Promise<UserGetProfileResponse> {
    const result = await fetch('/api/user/getprofile');
    const json = await result.json();

    return json as UserGetProfileResponse;
}

export function userSetProfile(
    name: string,
    favoriteColor: number,
    showReplay: boolean,
): Promise<any> {
    return fetchPost(
        '/api/user/setprofile',
        { name, favoriteColor, showReplay },
    );
}

export function userChangePassword(
    oldPassword: string,
    newPassword: string,
): Promise<any> {
    return fetchPost(
        '/api/user/changepassword',
        { oldPassword, newPassword },
    );
}
export function userChangePasswordWithJwt(
    userId: number,
    token: string,
    newPassword: string,
): Promise<any> {
    return fetchPost(
        '/api/user/changepasswordwithjwt',
        { userId, token, newPassword },
    );
}

export function userChangeEmail(
    email: string,
    password: string,
): Promise<any> {
    return fetchPost(
        '/api/user/changeemail',
        { email, password },
    );
}

export function verififyEmail(
    email: string,
): Promise<any> {
    return fetchPost(
        '/api/user/sendverifyemail',
        { email },
    );
}

export function setReplayForGame(
    gameId: number,
    showReplay: boolean,
): Promise<any> {
    return fetchPost(
        '/api/user/setreplayforgame',
        { gameId, showReplay }
    );
}

export function userSetPushSubscription(
    endpoint: string,
    subscription: string,
): Promise<any> {
    return fetchPost(
        '/api/user/setpushsubscription',
        { endpoint, subscription }
    );
}
