import { OverlayToaster, OverlayToasterCreateOptions, OverlayToasterProps, Toaster } from "@blueprintjs/core";
import { createRoot } from 'react-dom/client';

/**
 * Create a toast async. Wraps Blueprint's call
 * BUT uses React.createRoot to avoid an annoying
 * warning in the console about how React 18
 * doesn't support however Blueprint mounts these
 * now
 */
export default async function createToasterAsync(
    props?: OverlayToasterProps,
    options?: OverlayToasterCreateOptions,
): Promise<Toaster> {
    const toaster = await OverlayToaster.createAsync(
        {
            ...props,
        },
        {
            domRenderer: (t, containerElement) => createRoot(containerElement).render(t),
            ...options,
        },
    );

    return toaster;
}
