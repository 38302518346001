import Image from "next/image";
import SAnchorButton from "../sanchorbutton";
import { Checkbox, Intent } from "@blueprintjs/core";
import { ChangeEvent, useCallback, useState } from "react";
import { getLocalStorageKeyVerifyNotif } from "../../../src/enum/localstorage";
import { SpqrUrls } from "../../../src/consts/urls";

/** Size of the image thumbnail */
const IMAGE_SIZE = 96;

type Props = {
    userId: number, // user id of currently logged in user
};

/**
 * Toast notification asking user to verify their email
 */
export default function VerifyNotifToast(
    {
        userId,
    }: Props,
): JSX.Element {
    const [notifyAgain, setNotifyAgain] = useState<boolean>(true);

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = !event.currentTarget.checked;
            setNotifyAgain(newValue);

            localStorage.setItem(
                getLocalStorageKeyVerifyNotif(userId),
                String(newValue),
            );
        },
        [setNotifyAgain, userId],
    );

    return <div
        style={{
            display: 'flex',
            gap: '12px',
        }}
    >
        <div>
            <Image
                src='/images/emojis/victory.png'
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                alt='Caesar looking at his wristwatch'
            />
        </div>
        <div>
            <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>Don&apos;t miss a turn!</div>
            <div>
                Verify your email to receive important game updates.
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: '16px',
                }}
            >
                <div>
                    <Checkbox
                        checked={!notifyAgain}
                        label="Don't ask me again"
                        onChange={onChange}
                    />
                </div>
                <SAnchorButton
                    href={SpqrUrls.EditProfile}
                    intent={Intent.PRIMARY}
                >
                    Verify Email
                </SAnchorButton>
            </div>
        </div>
    </div>
}
