import React, { useCallback, useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { UserGetProfileResponse } from "../../src/types/user";
import { Position } from "@blueprintjs/core";
import createToasterAsync from "../../src/util/toaster";
import VerifyNotifToast from "./dialogs/verifynotif";
import { getLocalStorageKeyVerifyNotif, getLocalStorageKeyVerifyNotifTime } from "../../src/enum/localstorage";
import { useRouter } from "next/router";
import { SpqrUrls } from "../../src/consts/urls";
import { userGetProfile } from "../../src/thunks/user";
import { ONE_DAY } from "../../src/util/relativetime";

/**
 * Show a notification to the user asking them to verify their email
 *
 * Shows when:
 *     1. The user is logged in
 *     2. The user has not verified their email
 *     3. The user is not currently on the Edit Profile page to verify their email
 *     4. The user has not manually suppressed this notification
 */
export default function VerifyNotif(): React.ReactNode {
    const [profile, setProfile] = useState<UserGetProfileResponse>(null);

    const router = useRouter();
    const { data: session } = useSession();
    const userId = session?.user?.id;

    // load profile to see if user has verified their email
    // and store it in the state
    const loadProfile = useCallback(
        async () => {
            const result = await userGetProfile();
            setProfile(result);
        },
        [setProfile],
    );

    useEffect(
        () => {
            // if the user is already editing their profile, don't prompt them
            if (router.route === SpqrUrls.EditProfile) {
                return;
            }

            // make sure user is logged in
            if (!userId) {
                return;
            }

            // check if user has suppressed the notification
            const key = getLocalStorageKeyVerifyNotif(userId);
            const value = localStorage.getItem(key);
            if (value === 'false') {
                return;
            }

            const timeKey = getLocalStorageKeyVerifyNotifTime(userId);
            const timeValue = Number(localStorage.getItem(timeKey)) || 0;
            const dateDelta = Date.now() - timeValue;
            if (dateDelta < ONE_DAY) {
                return;
            }

            loadProfile();
        },
        [loadProfile, router, userId],
    );

    const showToast = useCallback(
        async () => {
            const toaster = await createToasterAsync(
                {
                    position: Position.BOTTOM_RIGHT,
                },
            );

            toaster.show({
                message: <VerifyNotifToast userId={userId} />,
                timeout: 30000,
            });

            const timeKey = getLocalStorageKeyVerifyNotifTime(userId);
            localStorage.setItem(timeKey, String(Date.now()));
        },
        [userId],
    );

    useEffect(
        () => {
            if (!profile || profile.emailVerified) {
                return;
            }

            showToast();
        },
        [profile, showToast],
    );

    return <></>;
}
